<template>
  <v-row
    class="main-container"
    no-gutters>
    <v-col cols="5">
      <CurvedHeader
        title="CREATE A NEW PASSWORD"
        :organization-logo="organizationLogo" />
    </v-col>
    <v-col
      class="ma-auto"
      cols="4">
      <app-bar title="Go Back"></app-bar>
      <v-form
        @submit.prevent="sent = true"
        class="px-6">
        <v-row no-gutters>
          <v-text-field
            label="Create a password (min 8 chars)"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            hint="At least 8 characters"
            color="primaryColor"
            v-model="newPassword"
            class="my-4 px-4">
            <template v-slot:append>
              <v-img
                height="40"
                width="40"
                :src="showPassword ? eye : eyeOff" />
            </template>
          </v-text-field>
        </v-row>

        <v-row
          justify="center"
          no-gutters>
          <v-col cols="10">
            <v-btn
              type="submit"
              class="brand-gradient"
              :loading="isCreatingANewPassword"
              :disabled="isCreatingANewPassword"
              dark
              rounded
              block
              large
              depressed
              >Save new password</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
  import PasswordResetMixin from '@/views/auth/password-reset/PasswordReset.mixin.vue';
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  export default {
    mixins: [PasswordResetMixin],
    components: {
      CurvedHeader, //we don't have the CurvedHeader in mobile that's why we didn't imported to the mixin
    },
  };
</script>

<style lang="css" scoped>
  .main-container {
    height: 100%;
  }
  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(51, 51, 51, 0.161) !important;
  }
</style>
