<script>
  import { mapMutations, mapGetters } from 'vuex';
  import AppBar from '@/components/general/app-bar/AppBar.vue';

  export default {
    data() {
      return {
        isCreatingANewPassword: false,
        newPassword: '',
        showPassword: false,
      };
    },
    components: {
      AppBar,
    },
    computed: {
      ...mapGetters('OrganizationsModule', {
        organizationLogo: 'getOrganizationLogoUrl',
      }),
      eye() {
        return require('@/assets/auth/eye.png');
      },
      eyeOff() {
        return require('@/assets/auth/eye-off.png');
      },
    },
    mounted() {
      const eyesIcon = document.getElementsByClassName('v-input__append-inner')[0];
      eyesIcon?.addEventListener('click', this.toggleShowPassword);
    },
    beforeDestroy() {
      const eyesIcon = document.getElementsByClassName('v-input__append-inner')[0];
      eyesIcon?.removeEventListener('click', this.toggleShowPassword);
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage']),
      toggleShowPassword() {
        this.showPassword = !this.showPassword;
      },
      async createANewPassword() {
        this.isCreatingANewPassword = true;

        try {
          // do something in the backend and then redirect to login
          this.$router.push({
            name: 'Login',
          });
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isCreatingANewPassword = false;
        }
      },
    },
  };
</script>
